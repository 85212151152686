import { Container } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { SalesChartComponent } from "../components/SalesChartComponent";
import { SalesHeaderComponent } from "../components/SalesHeaderComponent";
import { AuthContext } from "../context/AuthContext";
import { ProjectsContext } from "../context/ProjectsContext";
import { SalesContext } from "../context/SalesContext";
import loadSales from "../functions/loadSales";
import { GridContainer, GridDivider, Loading } from "../themes/themes";
import convertDate from "../utils-functions/convertDate";
import getDayMonthYear from "../utils-functions/getDayMonthYear";

const DATE = new Date();
const MONTH = parseInt(getDayMonthYear(DATE).month);
const YEAR = parseInt(getDayMonthYear(DATE).year);

export default function ProjectsChartPage() {
    const { sales, setSales, yearsLoaded } = useContext(SalesContext);
    const { projects, setProjects } = useContext(ProjectsContext);
    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);

    const [chartType, setChartType] = useState("Projects");
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [month, setMonth] = useState(MONTH);
    const [year, setYear] = useState(YEAR);
    const [type, setType] = useState(["project"]);
    const [display, setDisplay] = useState("year");
    const [status, setStatus] = useState(["BOOKED", "BILLED", "TO-INVOICE"]);

    useEffect(() => {
        if (!sales) getSalesData();
        console.log(sales);
    }, []);

    const getSalesData = async () => {
        setLoading(true);
        const sales = await loadSales(user, year, year, yearsLoaded);
        if (sales) setSales(sales);
        setLoading(false);
    };

    const checkStatus = (value) => {
        switch (value) {
            case "booked":
                if (status.includes("BOOKED")) return true;
                break;
            case "converted":
                if (status.includes("BILLED") || status.includes("TO-INVOICE")) return true;
                break;
            case "cancelled":
                if (status.includes("CANCELLED")) return true;
                break;
            case "BOOKED":
            case "BILLED":
            case "TO-INVOICE":
            case "CANCELLED":
                if (status.includes(value)) return true;
                break;
            default:
                return false;
        }
    };

    const checkType = (value) => {
        if (type.includes(value)) return true;
        return false;
    };

    const projectsChart = useMemo(() => {
        if (sales) {
            const salesToDisplay = sales.filter((sale) => {
                if (display === "month") {
                    console.log(month, year);
                    const startDate = new Date(year, month - 1, 1, 0, 0, 0);
                    const endDate = new Date(year, month, 0, 23, 59, 59);
                    if (
                        convertDate(sale.bookingDate) >= startDate &&
                        convertDate(sale.bookingDate) <= endDate &&
                        checkStatus(sale.status) &&
                        checkType(sale.type)
                    )
                        return true;
                } else if (display === "year") {
                    console.log("year", year);
                    const startDate = new Date(year, 0, 1, 0, 0, 0);
                    const endDate = new Date(year, 12, 31, 23, 59, 59);
                    if (
                        convertDate(sale.bookingDate) >= startDate &&
                        convertDate(sale.bookingDate) <= endDate &&
                        checkStatus(sale.status) &&
                        checkType(sale.type)
                    )
                        return true;
                }
            });
            const projects = convertToProjects(salesToDisplay);
            console.log(projects);
            if (projects) {
                const projectsChart = projects.map((project) => {
                    return {
                        name: project.name,
                        netPrice: project.count
                    };
                });
                return projectsChart;
            }
            return projectsChart;
        } else {
            return;
        }
    }, [sales, month, year, projects, status, type, startDate, endDate, display]);

    const totalSales = useMemo(() => {
        return 0;
    }, []);

    return (
        <Container disableGutters>
            <GridContainer>
                <SalesHeaderComponent
                    chartType={chartType}
                    setChartType={setChartType}
                    startDate={startDate}
                    display={display}
                    setDisplay={setDisplay}
                    month={month}
                    setMonth={setMonth}
                    year={year}
                    setYear={setYear}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    setStartDate={setStartDate}
                    checkStatus={checkStatus}
                    user={user}
                    checkType={checkType}
                    totalSales={totalSales}
                    setTypes={setType}
                    setLoading={setLoading}
                    sales={sales}
                    setSales={setSales}
                    yearsLoaded={yearsLoaded}
                    status={status}
                    setStatus={setStatus}
                    type={type}
                    setType={setType}
                />
                {projectsChart?.map((project, i) => (
                    <SalesChartComponent i={i} sale={project} salesRanking={projectsChart} key={i} />
                ))}

                <Loading loading={loading} />
                <GridDivider />
            </GridContainer>
        </Container>
    );
}

const convertToProjects = (sales) => {
    console.log(sales);
    let projects = {};
    let array = [];
    sales.forEach((sale) => {
        if (!sale.project) return;
        const projectId = `${sale.project} - ${sale.unitNo}`;
        const f = array.find((doc) => doc === projectId);
        if (f) return;
        array.push(projectId);
        projects[sale.project] = {
            name: sale.project,
            count: projects[sale.project] ? projects[sale.project].count + 1 : 1
        };
    });
    const projectsArray = Object.keys(projects).map((key) => {
        return {
            name: key,
            count: projects[key]["count"]
        };
    });
    projectsArray.sort((a, b) => b.count - a.count);
    console.log(projectsArray);
    return projectsArray;
};
